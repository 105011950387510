import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, Grid, Icon } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import BottomMenus from "layouts/dashboards/BottomNavigation/bottom-menus";
import DepositForm from "layouts/deposit/deposit-form";
import { UpdateDeposit } from "api/deposit";
import appbarlogo from "assets/png/appbarlogo.png";
import DepositHistory from "./deposit-history";

function Deposit() {
  const [deposit, setDeposit] = useState();
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  async function CancelDeposit() {
    const payload = {
      deposit_uid: deposit?.deposit_uid,
      status_code: "cancel",
    };
    try {
      const res = await UpdateDeposit(payload);
      console.log("CancelDeposit-res", res);
      history.push(`/dashboard`);
    } catch (e) {
      history.push(`/dashboard`);
    }
  }

  function handleBack() {
    if (deposit?.deposit_uid) {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-info",
          cancelButton: "button button-error",
        },
      });
      newSwal.fire({
        title: "ยกเลิกรายการ",
        text: `ต้องการยกเลิกรายการฝากจำนวน ${deposit?.amount}`,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        showCancelButton: true,
        allowOutsideClick: false,
        focusConfirm: false,
        preConfirm: () => {
          CancelDeposit();
        },
      });
    } else {
      history.push(`/dashboard`);
    }
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    // getPendingDeposit()
  }, []);

  console.log("deposit-deposit", deposit);

  return (
    <DashboardLayout height="100vh">
      <SuiBox display="flex" height="100vh">
        <Grid container spacing={3}>
          {!mobileView && <Grid item xs={12} lg={3} />}
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex">
              <Card
                style={{
                  flex: 1,
                  backgroundColor: "#0000002e",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <SuiBox display="flex" flexDirection="column" p={3}>
                  <SuiBox display="flex" alignItems="center">
                    <SuiButton
                      variant="outlined"
                      buttonColor="white"
                      size="small"
                      circular
                      iconOnly
                      onClick={() => handleBack()}
                    >
                      <Icon className="" color="inherit">
                        arrow_back
                      </Icon>
                    </SuiButton>
                    <SuiBox
                      ml={2}
                      component="img"
                      src={appbarlogo}
                      alt={appbarlogo}
                      width="30%"
                      display="inherit"
                    />
                  </SuiBox>
                  {user?.member_uid && (
                    <SuiBox mt={2}>
                      <DepositForm onSetDeposit={(d) => setDeposit(d)} />
                      <SuiBox mt={2}>
                        <DepositHistory onSetDeposit={(d) => setDeposit(d)} />
                      </SuiBox>
                    </SuiBox>
                  )}
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={3} />}
        </Grid>
      </SuiBox>
      {user?.token && <BottomMenus value={0} />}
    </DashboardLayout>
  );
}

export default Deposit;
