import PropTypes from "prop-types";
import SuiAvatar from "components/SuiAvatar";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSnackbar from "components/SuiSnackbar";
import { Card, Icon } from "@mui/material";
// import KbankLogo from "assets/bankimg/004.png";
// import TrueLogo from "assets/bankimg/999.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SuiButton from "components/SuiButton";
import { formatter } from "utils/validations";
// import SuiButton from "components/SuiButton";

const API_SERVER = "https://www.29agentxapi.com/";

function DepositInfo({ amount, defaultBank }) {
  const [infoSB, setInfoSB] = useState(false);

  const renderInfoSB = (
    <SuiSnackbar
      icon="copy"
      type="info"
      title={`${defaultBank?.bank_name}: ${defaultBank?.agent_bank_number}`}
      open={infoSB}
      onClose={() => setInfoSB(false)}
      close={() => setInfoSB(false)}
    />
  );

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  // let bankLogo = KbankLogo;
  // if (defaultBank?.bank_code === "999") {
  //   bankLogo = TrueLogo;
  // }

  return (
    <SuiBox>
      <SuiBox textAlign="center">
        <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="secondary">
          ยอดที่ต้องโอน
        </SuiTypography>
        <SuiTypography variant="h1" fontWeight="bold" textAlign="center">
          {formatter.format(amount).toString()}
        </SuiTypography>
      </SuiBox>
      <SuiBox display="flex" flexDirection="column" textAlign="center" p={2}>
        <SuiBox display="flex" justifyContent="center">
          <SuiAvatar
            src={`${API_SERVER}images/banks/${defaultBank?.bank_code}.png`}
            alt={defaultBank.bank_name}
            size="sm"
            variant="rounded"
          />
          {/* <SuiAvatar src={bankLogo} alt={defaultBank.bank_name} size="sm" variant="rounded" /> */}
        </SuiBox>
        <SuiTypography mt={1} component="span" variant="caption" fontWeight="bold" textColor="dark">
          {defaultBank.bank_name}
        </SuiTypography>
        <SuiTypography mt={1} component="span" variant="caption" fontWeight="bold" textColor="dark">
          {defaultBank.agent_bank_name}
        </SuiTypography>
        <SuiTypography mt={1} component="span" variant="caption" fontWeight="bold" textColor="dark">
          {defaultBank.agent_bank_number}
        </SuiTypography>
        <SuiBox mt={1} display="flex" justifyContent="center">
          <CopyToClipboard text={defaultBank.agent_bank_number} onCopy={() => setInfoSB(true)}>
            <SuiButton
              display="flex"
              alignItems="center"
              variant="gradient"
              buttonColor="light"
              size="small"
            >
              <Icon className="text-dark">copy</Icon>
              <SuiTypography component="span" variant="caption" fontWeight="bold" textColor="dark">
                คัดลอก
              </SuiTypography>
            </SuiButton>
          </CopyToClipboard>
        </SuiBox>
      </SuiBox>

      {renderInfoSB}
      <SuiBox textAlign="center">
        {defaultBank?.bank_code === "999" ? (
          <SuiTypography component="label" variant="caption" textColor="error">
            **โอนเงินแล้วกรุณาแจ้งสลิปทางไลน์ทุกครั้ง
          </SuiTypography>
        ) : (
          <SuiTypography component="label" variant="caption" textColor="secondary">
            **กรุณาตรวจสอบยอดเงินและทศนิยมก่อนโอน
            เพื่อความรวดเร็วในการทำรายการกรุณาโอนตามยอดที่แสดงนี้
          </SuiTypography>
        )}
      </SuiBox>
      <SuiBox textAlign="center">
        <Card>
          <SuiBox p={1} backgroundColor="warning" display="flex" flexDirection="column">
            <SuiTypography component="label" variant="caption" textColor="dark">
              กรุณาใช้บัญชีที่ท่านได้ทำการสมัครไว้ในระบบ
            </SuiTypography>
            <SuiTypography component="label" variant="caption" textColor="dark">
              ธนาคาร: {user?.bank_name}
            </SuiTypography>
            <SuiTypography component="label" variant="caption" textColor="dark">
              เลขที่บัญชี: {user?.member_bank_number}
            </SuiTypography>
          </SuiBox>
        </Card>
      </SuiBox>
    </SuiBox>
  );
}

DepositInfo.defaultProps = {
  amount: "",
  defaultBank: {},
};

DepositInfo.propTypes = {
  amount: PropTypes.string,
  defaultBank: PropTypes.shape,
};

export default DepositInfo;
