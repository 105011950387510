import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Card, Grid, Icon } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BottomMenus from "layouts/dashboards/BottomNavigation/bottom-menus";
import breakpoints from "assets/theme/base/breakpoints";
import appbarlogo from "assets/png/appbarlogo.png";
import WithdrawFrom from "./withdraw-form";
import WithdrawHistory from "./withdraw-history";

function Withdraw() {
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  function handleBack() {
    history.push(`/dashboard`);
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);

  return (
    <DashboardLayout height="100vh">
      <SuiBox display="flex" height="100vh">
        <Grid container spacing={3}>
          {!mobileView && <Grid item xs={12} lg={3} />}
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex">
              <Card
                style={{
                  flex: 1,
                  backgroundColor: "#0000002e",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <SuiBox display="flex" flexDirection="column" p={3}>
                  <SuiBox display="flex" alignItems="center">
                    <SuiButton
                      variant="outlined"
                      buttonColor="white"
                      size="small"
                      circular
                      iconOnly
                      onClick={() => handleBack()}
                    >
                      <Icon className="" color="inherit">
                        arrow_back
                      </Icon>
                    </SuiButton>
                    <SuiBox
                      ml={2}
                      component="img"
                      src={appbarlogo}
                      alt={appbarlogo}
                      width="30%"
                      display="inherit"
                    />
                  </SuiBox>
                  {user?.member_uid && (
                    <SuiBox mt={2}>
                      <WithdrawFrom />
                      <SuiBox mt={2}>
                        <WithdrawHistory />
                      </SuiBox>
                    </SuiBox>
                  )}
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={3} />}
        </Grid>
      </SuiBox>
      {user?.token && <BottomMenus value={1} />}
    </DashboardLayout>
  );
}

export default Withdraw;
