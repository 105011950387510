import { useState, useEffect } from "react";
import { Card, CircularProgress, Grid } from "@mui/material";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import { ResponseAlert } from "components/ResponseAlert";
import DataTable from "examples/Tables/DataTable";
import SuiTypography from "components/SuiTypography";
import { withdrawTransaction } from "api/member";

const columns = [
  { Header: "วันที่/เวลา", accessor: "createdAt" },
  { Header: "จำนวน(บาท)", accessor: "amount" },
  { Header: "สถานะ", accessor: "status_code" },
];

function WithdrawHistory() {
  const [withdrawTableData, setWithdrawTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);

  async function getWithdrawList() {
    setLoading(true);
    try {
      const response = await withdrawTransaction();
      console.log("getWithdrawList-response", response);
      setLoading(false);
      const status = response?.data?.status;
      if (status === "success") {
        const withdrawList = response.data?.data ?? [];
        const newWithdrawList = withdrawList.reduce((a, b) => {
          const newB = { ...b };
          if (!b?.manual_by) {
            let statusCode = "";
            let statusColor = "";
            if (b?.status_code === "success") {
              statusCode = "สำเร็จ";
              statusColor = "success";
            }
            if (b?.status_code === "pending") {
              statusCode = "รอทำรายการ";
              statusColor = "secondary";
            }
            if (b?.status_code === "reject") {
              statusCode = "ปฎิเสธ";
              statusColor = "error";
            }
            newB.status_code = (
              <SuiBox display="flex" justifyContent="center" alignItems="center">
                <SuiBox position="absolute" customClass="object-center object-center" zIndex={0}>
                  <SuiBadge
                    variant="contained"
                    color={statusColor}
                    badgeContent={statusCode}
                    container
                  />
                </SuiBox>
              </SuiBox>
            );
          }
          a.push(newB);
          return a;
        }, []);

        setWithdrawTableData({ columns, rows: newWithdrawList });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    getWithdrawList();
  }, []);

  return (
    <SuiBox px={2} lineHeight={1.25} textAlign="center">
      <Grid item xs={12}>
        <Card>
          {isLoading ? (
            <SuiBox p={3} lineHeight={0} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="secondary" />
            </SuiBox>
          ) : (
            <SuiBox p={2}>
              <SuiTypography variant="h5" fontWeight="medium">
                ประวัติการถอน
              </SuiTypography>
              <DataTable
                table={withdrawTableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
              />
            </SuiBox>
          )}
        </Card>
      </Grid>
    </SuiBox>
  );
}

export default WithdrawHistory;
